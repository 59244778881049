import { PageSettingListModel } from "@Features/common/domain/LoadPageSettings.usecase";
import { STOCK_KEY_TEASER } from "@Libraries/utils/Const";
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FetchStockKeyInsightSettingsUsecase } from "../domain/FetchStockKeyInsightSettings.usecase";
import { FeaturesSection } from "./components/FeaturesSections.component";
import { TeaserSection } from "./components/TeaserSections.component";
import { ThreeColumnSections } from "./components/ThreeColumnSections.component";
import { TopHeaderSections } from "./components/TopHeaderSections.component";
import { WaButtonFloating } from "@Libraries/components/wa-button/WaButton.component";

export const StockKeyInsightPage: FC = () => {
  const [pageSetting, setPageSetting] = useState<PageSettingListModel>({});

  useEffect(() => {
    FetchStockKeyInsightSettingsUsecase()
      .then((response) => {
        setPageSetting(response);
      })
      .catch((errors) => {});
  }, []);

  return (
    <>
      <Helmet encodeSpecialCharacters={true}>
        <title>Stock Key Insight - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Stock Key Insight" />
      </Helmet>
      <section>
        <TopHeaderSections />
      </section>
      <section>
        <ThreeColumnSections />
      </section>
      <section>
        <FeaturesSection />
      </section>
      {pageSetting[STOCK_KEY_TEASER] && (
        <section>
          <TeaserSection data={JSON.parse(pageSetting[STOCK_KEY_TEASER])} />
        </section>
      )}
      <WaButtonFloating/>
    </>
  );
};
