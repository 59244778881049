import {
  CourseItemModel,
  CourseModel,
  CourseSectionModel,
} from "@Features/dashboard/data/course.model";
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { MidasShimmering } from "@Libraries/components/loader/MidasShimmering.component";
import { MidasVideoPlayer } from "@Libraries/components/video-player/VideoPlayer.component";
import { UserSession } from "@Libraries/users/UserSession";
import { getSiteMenus } from "@Libraries/utils/Const";
import { FC, RefObject, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaCheckCircle,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { GetCourseDetailUsecase } from "../domain/GetCourseDetail.usecase";
import { UpdateCourseProgress } from "../domain/UpdateCourseProgress.usecase";
import { DividerComponent } from "@Libraries/components/divider/Divider.component";
import { WaButtonFloating } from "@Libraries/components/wa-button/WaButton.component";

export type ActiveCourseModel = {
  sectionIndex: number;
  courseIndex: number;
  videoUrl: string;

  courseItemId: number;
};

export type CourseSectionProps = CourseSectionModel & {
  sectionIndex: number;
  activeSectionIndex: number;
  activeCourseIndex: number;
  onClick: (
    sectionIndex: number,
    courseIndex: number,
    videoUrl: string,
    courseItemId: number
  ) => void;
};

export type CourseItemProps = CourseItemModel & {
  sectionIndex: number;
  courseIndex: number;
  activeSectionIndex: number;
  activeCourseIndex: number;
  onClick: (
    sectionIndex: number,
    courseIndex: number,
    videoUrl: string,
    courseItemId: number
  ) => void;
};

export const CourseItemComponent: FC<CourseItemProps> = ({
  id,
  sectionIndex,
  activeCourseIndex,
  activeSectionIndex,
  courseIndex,
  description,
  duration_in_seconds,
  onClick,
  title,
  video_url,
  is_completed,
}) => {
  const convertDurationInSeconds = () => {
    let durationInSeconds = parseInt(duration_in_seconds);

    let durationHours = Math.floor(durationInSeconds / (60 * 60));
    durationInSeconds -= durationHours * 60 * 60;

    let durationMinutes = Math.floor(durationInSeconds / 60);
    durationInSeconds -= durationMinutes * 60;

    return `${durationHours > 0 ? `${durationHours}j` : ""}${
      durationMinutes > 0 ? `${durationMinutes}m` : ""
    }${durationInSeconds > 0 ? `${durationInSeconds}d` : ""}`;
  };

  return (
    <div
      className={`pt-2 pb-2 px-4 rounded flex flex-row gap-4 text-white ${
        sectionIndex === activeSectionIndex && courseIndex === activeCourseIndex
          ? "bg-gray-500"
          : ""
      }`}
      onClick={() => {
        if (
          sectionIndex !== activeSectionIndex ||
          courseIndex !== activeCourseIndex
        ) {
          onClick(sectionIndex, courseIndex, video_url, id);
        }
      }}
    >
      {is_completed === true && (
        <div className="my-auto">
          <FaCheckCircle size={16} className="text-green-500" />
        </div>
      )}
      <div className="ml-0 my-auto w-full">
        <div className="text-base font-bold">{title}</div>
        {description.length > 0 && (
          <div className="text-sm mt-4 text-left">{description}</div>
        )}
      </div>
      <div className="hidden md:block my-auto text-sm font-normal text-right">
        {convertDurationInSeconds()}
      </div>
    </div>
  );
};

export const CourseSectionComponent: FC<CourseSectionProps> = ({
  title,
  items,
  sectionIndex,
  activeCourseIndex,
  activeSectionIndex,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="mt-3">
      <div
        className="text-white font-bold flex text-xl justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{title}</div>
        <div className="my-auto ml-4">
          {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      <DividerComponent/>
      {isOpen &&
        items.map((course, courseIndex) => {
          return (
            <div key={`course-item-${title}-${course.title}`}>
              <div className="w-full border-t border-neutral-800"></div>
              <CourseItemComponent
                {...course}
                activeCourseIndex={activeCourseIndex}
                activeSectionIndex={activeSectionIndex}
                courseIndex={courseIndex}
                sectionIndex={sectionIndex}
                onClick={onClick}
              />
            </div>
          );
        })}
    </div>
  );
};

export const CourseDetailPage = () => {
  const { slug } = useParams();

  const playerRef = useRef<HTMLDivElement | undefined>();

  const [courseDetail, setCourseDetail] = useState<CourseModel>();
  const [activeCourseIndex, setActiveCourseIndex] = useState<ActiveCourseModel>(
    { sectionIndex: -1, courseIndex: -1, videoUrl: "", courseItemId: -1 }
  );
  const [lastPercentProgressSent, setLastPercentProgressSent] = useState(-1);

  useEffect(() => {
    GetCourseDetailUsecase(slug ?? "")
      .then((response) => {
        setCourseDetail(response);
        setActiveCourseIndex({
          sectionIndex: 0,
          courseIndex: 0,
          videoUrl: response.sections[0].items[0].video_url,
          courseItemId: response.sections[0].items[0].id,
        });
      })
      .catch((errors) => {
        if (errors.message === "Not Authorized") {
          let baseUrl = window.location.origin;
          UserSession.emptySession();
          window.location.assign(
            `${baseUrl}/login?continue=${window.location.href}`
          );
        }
      });
  }, [slug]);

  const getPrevTitle = () => {
    if (activeCourseIndex.courseIndex > 0) {
      return (
        courseDetail?.sections[activeCourseIndex.sectionIndex].items[
          activeCourseIndex.courseIndex - 1
        ].title ?? ""
      );
    } else if (activeCourseIndex.sectionIndex > 0) {
      return (
        courseDetail?.sections[activeCourseIndex.sectionIndex - 1].items[
          courseDetail.sections[activeCourseIndex.sectionIndex - 1].items
            .length - 1
        ].title ?? ""
      );
    }
    return "";
  };

  const onGoPrev = () => {
    let newData = { ...activeCourseIndex };

    if (activeCourseIndex.courseIndex > 0) {
      newData.sectionIndex = activeCourseIndex.sectionIndex;
      newData.courseIndex = activeCourseIndex.courseIndex - 1;
      newData.videoUrl =
        courseDetail?.sections[activeCourseIndex.sectionIndex].items[
          activeCourseIndex.courseIndex - 1
        ].video_url ?? "";
      newData.courseItemId =
        courseDetail?.sections[activeCourseIndex.sectionIndex].items[
          activeCourseIndex.courseIndex - 1
        ].id ?? -1;

      setActiveCourseIndex(newData);
    } else if (activeCourseIndex.sectionIndex > 0) {
      let courseCount =
        courseDetail?.sections[activeCourseIndex.sectionIndex - 1].items
          .length ?? 0;

      newData["sectionIndex"] = activeCourseIndex.sectionIndex - 1;
      newData["courseIndex"] = courseCount - 1;
      newData["videoUrl"] =
        courseDetail?.sections[activeCourseIndex.sectionIndex - 1].items[
          courseCount - 1
        ].video_url ?? "";
      newData.courseItemId =
        courseDetail?.sections[activeCourseIndex.sectionIndex - 1].items[
          courseCount - 1
        ].id ?? -1;

      setActiveCourseIndex(newData);
    }
  };

  const getNextTitle = () => {
    let courseItemsInSection =
      courseDetail?.sections[activeCourseIndex.sectionIndex].items.length ?? 0;
    let sectionsNumber = courseDetail?.sections.length ?? 0;

    if (activeCourseIndex.courseIndex < (courseItemsInSection - 1 ?? 0)) {
      return courseDetail?.sections[activeCourseIndex.sectionIndex].items[
        activeCourseIndex.courseIndex + 1
      ].title;
    } else if (activeCourseIndex.sectionIndex < sectionsNumber - 1) {
      return courseDetail?.sections[activeCourseIndex.sectionIndex + 1].items[0]
        .title;
    }
    return "";
  };

  const onGoNext = () => {
    let courseItemsInSection =
      courseDetail?.sections[activeCourseIndex.sectionIndex].items.length ?? 0;
    let sectionsNumber = courseDetail?.sections.length ?? 0;

    if (activeCourseIndex.courseIndex < (courseItemsInSection - 1 ?? 0)) {
      setActiveCourseIndex({
        ...activeCourseIndex,
        courseIndex: activeCourseIndex.courseIndex + 1,
        videoUrl:
          courseDetail?.sections[activeCourseIndex.sectionIndex].items[
            activeCourseIndex.courseIndex + 1
          ].video_url ?? "",
        courseItemId:
          courseDetail?.sections[activeCourseIndex.sectionIndex].items[
            activeCourseIndex.courseIndex + 1
          ].id ?? -1,
      });
    } else if (activeCourseIndex.sectionIndex < sectionsNumber - 1) {
      setActiveCourseIndex({
        ...activeCourseIndex,
        sectionIndex: activeCourseIndex.sectionIndex + 1,
        courseIndex: 0,
        videoUrl:
          courseDetail?.sections[activeCourseIndex.sectionIndex + 1].items[0]
            .video_url ?? "",
        courseItemId:
          courseDetail?.sections[activeCourseIndex.sectionIndex + 1].items[0]
            .id ?? -1,
      });
    }
    return "";
  };

  const sendCourseProgress = async (
    progressInPercent: number,
    progressInSecond: number
  ) => {
    await UpdateCourseProgress(
      activeCourseIndex.courseItemId,
      progressInPercent,
      progressInSecond
    );
  };

  return (
    <div className="bg-midas-neutral-900 h-screen overflow-scroll pb-12">
      <HeaderWithMenu shouldShowLoginButton={true} menuItems={getSiteMenus()} />
      {!courseDetail && <MidasShimmering />}
      {courseDetail && (
        <>
          <Helmet encodeSpecialCharacters={true}>
            <title>{courseDetail.title} - MIDAS Cuan</title>
            <meta
              name="description"
              content={`MIDAS Cuan - ${courseDetail.description}`}
            />
          </Helmet>

          <div className="text-white m-6 md:mx-14 lg:mx-24 flex gap-2">
            <Link className="my-auto font-semibold" to={"/member-area/courses"}>
              Course
            </Link>{" "}
            <FaChevronRight size={20} className="my-auto" />{" "}
            <div className="my-auto font-bold color-brand-yellow text-ellipsis">
              {courseDetail.title}
            </div>
          </div>

          <div className="w-full flex">
            <div className="w-1/3 hidden md:block">
              {courseDetail.sections.map((section, sectionIndex) => {
                return (
                  <div className="px-4">
                    <CourseSectionComponent
                      key={`course-section-${section.title}-${sectionIndex}`}
                      activeCourseIndex={activeCourseIndex.courseIndex}
                      activeSectionIndex={activeCourseIndex.sectionIndex}
                      {...section}
                      onClick={(
                        sectionIndex,
                        courseIndex,
                        videoUrl,
                        courseItemId
                      ) => {
                        playerRef.current?.scrollIntoView({
                          behavior: "smooth",
                        });
                        setActiveCourseIndex({
                          sectionIndex: sectionIndex,
                          courseIndex: courseIndex,
                          videoUrl: videoUrl,
                          courseItemId: courseItemId,
                        });
                      }}
                      sectionIndex={sectionIndex}
                    />
                  </div>
                );
              })}
            </div>
            <div className="w-full">
              <div ref={playerRef as RefObject<HTMLDivElement>}>
                <MidasVideoPlayer
                  componentKey={`course-${courseDetail.title}`}
                  videoUrl={activeCourseIndex.videoUrl}
                  options={{
                    widthPercentage: 100,
                    showVideoControls: true,
                    isAutoPlay: false,
                    canDownload: false,
                    videoSource:
                      activeCourseIndex.videoUrl.includes("youtube") ||
                      activeCourseIndex.videoUrl.includes("youtu.be")
                        ? "youtube"
                        : "url",
                  }}
                  onProgress={(state) => {
                    let progressInPercent: number = parseInt(
                      (state.played * 100).toFixed(0)
                    );
                    let progressInSecond: number = parseInt(
                      state.playedSeconds.toFixed(0)
                    );

                    if (
                      progressInPercent % 10 === 0 &&
                      lastPercentProgressSent !== progressInPercent
                    ) {
                      sendCourseProgress(progressInPercent, progressInSecond);
                      setLastPercentProgressSent(progressInPercent);
                    }
                  }}
                />
              </div>
              <div className="flex justify-between gap-4 md:gap-24 text-white my-12 px-6 text-lg">
                <button
                  type="button"
                  onClick={onGoPrev}
                  className="overflow-auto text-ellipsis flex gap-2"
                >
                  {!(
                    activeCourseIndex.sectionIndex === 0 &&
                    activeCourseIndex.courseIndex === 0
                  ) ? (
                    <>
                      <div className="my-auto">
                        <FaChevronLeft />
                      </div>
                      <div className="hidden md:inline-block">
                        {getPrevTitle()}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </button>
                <button
                  type="button"
                  onClick={onGoNext}
                  className="overflow-auto text-ellipsis flex gap-2"
                >
                  {!(
                    activeCourseIndex.sectionIndex ===
                      courseDetail.sections.length - 1 &&
                    activeCourseIndex.courseIndex ===
                      courseDetail.sections[courseDetail.sections.length - 1]
                        .items.length -
                        1
                  ) ? (
                    <>
                      <div className="hidden md:inline-block my-auto">
                        {getNextTitle()}
                      </div>
                      <div className="my-auto">
                        <FaChevronRight />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </button>
              </div>
              <div className="my-12 px-6 md:px-24">
                <div className="color-yellow text-black font-bold text-xs rounded-xl shadow w-fit py-1 px-2">
                  {courseDetail.level}
                </div>
                <div className="mt-4 text-white font-bold responsive-header-title">
                  {courseDetail.title}
                </div>
                <div className="mt-6 text-neutral-700 text-xl font-medium">
                  {courseDetail.part} Parts &#183; {courseDetail.videoCount}{" "}
                  Videos &#183; {courseDetail.duration}
                </div>
                <div className="mt-6 font-normal text-2xl text-left">
                  {courseDetail.description}
                </div>
                <div className="block md:hidden">
                  {courseDetail.sections.map((section, sectionIndex) => {
                    return (
                      <div className="mt-10 md:mt-20">
                        <CourseSectionComponent
                          key={`course-section-${section.title}-${sectionIndex}`}
                          activeCourseIndex={activeCourseIndex.courseIndex}
                          activeSectionIndex={activeCourseIndex.sectionIndex}
                          {...section}
                          onClick={(
                            sectionIndex,
                            courseIndex,
                            videoUrl,
                            courseItemId
                          ) => {
                            playerRef.current?.scrollIntoView({
                              behavior: "smooth",
                            });
                            setActiveCourseIndex({
                              sectionIndex: sectionIndex,
                              courseIndex: courseIndex,
                              videoUrl: videoUrl,
                              courseItemId: courseItemId,
                            });
                          }}
                          sectionIndex={sectionIndex}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <WaButtonFloating/>
    </div>
  );
};
