import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { getSiteMenus } from "@Libraries/utils/Const";
import { default as NotFoundImage } from "@Assets/homepage/not-found.webp";
import { Link } from "react-router-dom";
import { WaButtonFloating } from "@Libraries/components/wa-button/WaButton.component";

export const NotFoundPage = () => {
  return (
    <div className="bg-midas-neutral-900 h-screen overflow-scroll">
      <HeaderWithMenu shouldShowLoginButton={true} menuItems={getSiteMenus()} />
      <div className="mt-6 mx-auto md:mt-10 lg:mt-14 text-white w-full text-center">
        <div className="color-brand-yellow text-3xl font-bold mt-8 -mb-8">
          Silahkan login dulu ya..
        </div>
        
        <img
          src={NotFoundImage}
          alt="MidasCuan"
          className="w-full md:w-1/4 mx-auto"
        />
        <div className="color-brand-yellow text-3xl font-bold -mt-8">
          Klik tombol dibawah untuk login dulu.
        </div>
        <Link to={"/login"}>
          <button className="mt-6 button-yellow">Kembali ke halaman Login</button>
        </Link>
      </div>
      <WaButtonFloating/>
    </div>
  );
};
