import { PageSettingListModel } from "@Features/common/domain/LoadPageSettings.usecase";
import { MidasTestimoniComponent } from "@Features/common/presentation/components/MidasTestimoni.component";
import { MidasStockMembershipPriceComponent } from "@Libraries/components/prices/MidasStockMembershipPrice.component";
import { TextWithLeftImageComponent } from "@Libraries/components/text-image/TextWithLeftImage.component";
import { MidasVideoPlayer } from "@Libraries/components/video-player/VideoPlayer.component";
import {
  GENERAL_SELECTED_PRODUCTS,
  GENERAL_TESTIMONI,
  MEMBERSHIP_CAROUSEL,
  MEMBERSHIP_FAQ,
  MEMBERSHIP_FAQ_EMAIL,
  MEMBERSHIP_FINDING_STOCKS,
  MEMBERSHIP_HEADER_VIDEO,
  MEMBERSHIP_INTRODUCTION_VIDEO,
  MEMBERSHIP_SHOWCASE,
} from "@Libraries/utils/Const";
import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FetchMembershipSettingsUsecase } from "../domain/FetchMembershipSettings.usecase";
import { FAQSections } from "./components/FAQSections.component";
import { IntroductionSections } from "./components/IntroductionSections.component";
import { LinearCarouselSections } from "./components/LinearCarouselSections.component";
import { StockFindingSections } from "./components/StockFindingSections.component";
import { TopHeaderSections } from "./components/TopHeaderSections.component";
import { default as BukanStockPick } from "@Assets/midas-bukan-stock-pick.webp";
import { Helmet } from "react-helmet";
import { ShowcaseMembershipThumbnailSection } from "./components/ShowcaseMembershipThumbnailSection.component";
import { MediaSection } from "@Features/homepage/presentation/components/MediaSection.component";
import { WaButtonFloating } from "@Libraries/components/wa-button/WaButton.component";

export const MembershipPage: FC = () => {
  const navigate = useNavigate();
  const [pageSetting, setPageSetting] = useState<PageSettingListModel>({});

  const priceRef = useRef<null | HTMLElement>(null);

  useEffect(() => {
    FetchMembershipSettingsUsecase()
      .then((response) => {
        setPageSetting(response);
      })
      .catch((errors) => { });
  }, []);

  const onStartNowClicked = () => {
    navigate({
      hash: "start-now",
    });
    priceRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const getVideoUrl = () => {
    return pageSetting[MEMBERSHIP_INTRODUCTION_VIDEO]
      ? JSON.parse(pageSetting[MEMBERSHIP_INTRODUCTION_VIDEO]).videoUrl
      : "";
  };

  return (
    <>
      <Helmet encodeSpecialCharacters={true}>
        <title>Membership - MIDAS Cuan</title>
        <meta name="description" content="MIDAS Cuan - Membership" />
      </Helmet>

      <section>
        <TopHeaderSections
          onClick={onStartNowClicked}
          data={
            pageSetting[MEMBERSHIP_HEADER_VIDEO]
              ? JSON.parse(pageSetting[MEMBERSHIP_HEADER_VIDEO])
              : { videoUrl: "" }
          }
        />
      </section>

      {pageSetting[GENERAL_TESTIMONI] && (
        <section>
          <MidasTestimoniComponent
            carouselId="membership-testimoni"
            data={JSON.parse(pageSetting[GENERAL_TESTIMONI])}
          />
        </section>
      )}

      {pageSetting[MEMBERSHIP_FINDING_STOCKS] && (
        <section>
          <StockFindingSections
            data={JSON.parse(pageSetting[MEMBERSHIP_FINDING_STOCKS])}
          />
        </section>
      )}

      <section>
        <MediaSection />
      </section>

      <section>
        <TextWithLeftImageComponent
          image={
            <MidasVideoPlayer
              componentKey="MidasCuan - Introduction"
              videoUrl={getVideoUrl()}
              options={{
                widthPercentage: 100,
                showVideoControls: true,
                isAutoPlay: true,
                canDownload: false,
                videoSource: "youtube",
                height: 400,
              }}
            />
          }
          showButton={true}
          buttonLabel={"Gabung sekarang"}
          onClick={onStartNowClicked}
          textChildren={
            <>
              <div className="responsive-header-title text-white font-extrabold text-left">
                Hanya 4 Menit dengan{" "}
                <div className="color-brand-yellow">Stocks Treasure Map</div>
              </div>
              <div className="text-xl text-white mt-6">
                Ini cara kita menemukan saham berkualitas di tiap sektor dengan
                tools Stock Treasure Maps (Excel seluruh saham di bursa)
                available khusus member.
              </div>
            </>
          }
        />
      </section>

      {/* <section>
        <IntroductionSections
          data={
            pageSetting[MEMBERSHIP_INTRODUCTION_VIDEO]
              ? JSON.parse(pageSetting[MEMBERSHIP_INTRODUCTION_VIDEO])
              : { videoUrl: "" }
          }
        />
      </section>
      {pageSetting[MEMBERSHIP_CAROUSEL] && (
        <section>
           <LinearCarouselSections
            data={JSON.parse(pageSetting[MEMBERSHIP_CAROUSEL])}
          />
        </section>
      )} */}

      {pageSetting[MEMBERSHIP_SHOWCASE] && (
        <section>
          <ShowcaseMembershipThumbnailSection
            data={JSON.parse(pageSetting[MEMBERSHIP_SHOWCASE])}
            onClick={onStartNowClicked}
          />
        </section>
      )}

      {pageSetting[GENERAL_SELECTED_PRODUCTS] && (
        <section id="start-now" ref={priceRef} className="mx-0 md:mx-48">
          <MidasStockMembershipPriceComponent
            data={JSON.parse(pageSetting[GENERAL_SELECTED_PRODUCTS])}
          />
        </section>
      )}

      <div className="mt-4">
        <img
          src={BukanStockPick}
          alt="MIDAS CUAN - Bukan Stock Pick"
          className="mx-auto w-full sm:w-1/2 pt-10"
        />
      </div>

      <section>
        <FAQSections
          data={
            pageSetting[MEMBERSHIP_FAQ]
              ? JSON.parse(pageSetting[MEMBERSHIP_FAQ])
              : []
          }
          emailData={
            pageSetting[MEMBERSHIP_FAQ_EMAIL]
              ? JSON.parse(pageSetting[MEMBERSHIP_FAQ_EMAIL])
              : { email: "" }
          }
        />
      </section>
      <WaButtonFloating/>
    </>
  );
};
