import { ApiConst } from "@Libraries/networks/ApiConst";
import { client } from "@Libraries/networks/ApiServices";

export const BindTelegramUsecase: () => Promise<boolean> = async () => {
  try {
    let response = await client.post(ApiConst.API_BIND_TELEGRAM);
    return response.data.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};
