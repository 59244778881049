/* eslint-disable react-hooks/exhaustive-deps */
import { FooterComponent } from "@Libraries/components/footers/Footer.component";
import { HeaderWithMenu } from "@Libraries/components/headers/HeaderWithMenu.component";
import { UserSession } from "@Libraries/users/UserSession";
import { getSiteMenus } from "@Libraries/utils/Const";
import { FC, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { DashboardSidebarComponent } from "./components/DashboardSidebar.component";
import { NoAccessSection } from "./sections/NoAccessSection.component";
import { TrialCourseActive, TrialCourseAndPortofolioActive, TrialPortoActive } from "./sections/TrialActive.component";
import { GetProfileUsecase } from "@Features/profile/domain/GetProfile.usecase";
import { ProfileUiModel } from "@Features/profile/domain/model/Profile.ui.model";
import { CheckRoleExpiryUseCase } from "../domain/CheckRoleExpiry.usecase";
import { WaButtonFloating } from "@Libraries/components/wa-button/WaButton.component";

export const DashboardPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const ALLOWED_TO_ALL_PATH = [
    "member-area::upgrade",
    "member-area::perpanjang",
    "member-area::watchlist"
  ];

  const [hasAccess, setHasAccess] = useState<boolean>(true);
  const [trialPorto, setTrialPortoInfo] = useState<boolean>(false);
  const [trialCourse, setTrialCourseInfo] = useState<boolean>(false);
  const roleName = UserSession.getUserSession().activeRoles;
  const [networkData, setNetworkData] = useState<ProfileUiModel | null>(null);
  const [expiryRoles, setExpiryRoles] = useState<string[]>();

  useEffect(() => {
    let accessName = location.pathname.replaceAll("/", "::").replace("::", "");

    const getUserProfile = async () => {
      try {
        let userProfile = await GetProfileUsecase.execute();
        setNetworkData(userProfile);
      } catch (_) { }
    }

    const checkRolesExpiry = async () => {
      try {
        let willExpiredRoles = await CheckRoleExpiryUseCase();
        setExpiryRoles(willExpiredRoles);
      } catch (_) {

      }
    }

    if (!UserSession.checkIsLoggedIn()) {
      navigate("/login");
      return;
    }

    if (
      !UserSession.isUserCanAccessMemberArea(accessName) &&
      !ALLOWED_TO_ALL_PATH.includes(accessName)
    ) {
      setHasAccess(false);
    } else {
      setHasAccess(true);
    }

    for (let i = 0; i < roleName.length; i++) {
      if (roleName[i].role.title.split(" ")[0] === "Membership") {
        setTrialPortoInfo(false)
        setTrialCourseInfo(false)
        break
      }
      if (roleName[i].role.title === "Bonus for Portofolio") {
        setTrialPortoInfo(true)
      }
      if (roleName[i].role.title === "Bonus for Online Course") {
        setTrialCourseInfo(true)
      }
    }

    getUserProfile();
    checkRolesExpiry();
  }, [location.pathname]);

  return (
    <div className="w-screen bg-2e2e2e">
      <HeaderWithMenu shouldShowLoginButton={true} menuItems={getSiteMenus()} />
      <div>
        <div className="flex flex-col lg:flex-row">
          <div className="bg-midas-neutral-900 ">
            <DashboardSidebarComponent />
          </div>
          <div className="w-full p-5 lg:p-10">
            <div className="text-white text-2xl font-semibold">
              Hi, {UserSession.getName()}! <br />
              {expiryRoles && expiryRoles.length > 0 && expiryRoles.map((item, index) =>
                <div className="border border-black rounded color-yellow py-1 px-2 my-1">
                  <div key={`expired-role-${index}`} className="text-black text-base">{item}</div>
                </div>
              )}

              {/* show message if user have trial porto */}
              {trialPorto && !trialCourse && <TrialPortoActive />}

              {/* show message if user have trial online couse */}
              {!trialPorto && trialCourse && <TrialCourseActive />}

              {/* show message if user have trial porto and trial online course */}
              {trialPorto && trialCourse && <TrialCourseAndPortofolioActive />}
            </div>

            {/* show content if have access */}
            {hasAccess && <Outlet />}

            {/* show no access if doesn't have access */}
            {!hasAccess && <NoAccessSection />}

            {/* <a
              href="https://app.investasiku.id/referral/FELI240663"
              target={"_blank"}
              rel={"noreferrer"}
            >
              <img
                className="mt-10 mx-auto"
                src={InvestasikuImage}
                alt="MIDAS Cuan X InvestasiKu"
              />
            </a> */}
          </div>
        </div>
        <WaButtonFloating />
        <FooterComponent />
      </div>
    </div>
  );
};
